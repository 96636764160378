/**
 * Created with JetBrains PhpStorm.
 * User: gschulz
 * Date: 09.09.13
 * Time: 17:24
 */

let MathConvert = (function () {
    let _private = {};

    _private.instance = this;

    this.EARTH_RADIUS   = 6371;
    this.MILE_NAUTIC    = 1.852;
    this.KEROSIN_FACTOR = 0.8;

    _private.unitInfoCache = {};

    this.getValue = function (element, to) {
        if (_private.instance.isConvertElement(element)) {
            return _private.instance.convert(element, to);
        }
        return $(element).pVal();
    };

    this.setValue = function (element, value, to) {
        to = _.isUndefined(to) ? 'view' : to;
        if (_private.instance.isConvertElement(element)) {
            return _private.instance.convert(element, to, value);
        }
        return $(element).pVal(value);
    };

    this.isConvertElement = function (element) {
        element = $(element);
        return !(!element.length ||
            _.isUndefined(element.data('unit-base')) ||
            _.isUndefined(element.data('unit-view')) ||
            _.isUndefined(element.data('unit-type')));

    };

    this.setViewUnitToLabel = function (elements) {
        if (!_private.instance.isConvertElement(elements)) {
            elements = $(elements).find('input[data-unit-view]');
        }
        $(elements).each(function (i, element) {
            let viewUnit = $(element).data('unit-view');
            let label    = $(element).prev('label');
            if (!label.length) {
                return;
            }
            let unitInfo = _private.instance.getUnitInfo(viewUnit);
            if (!unitInfo) {
                return;
            }
            $(label).text($(label).text().replace(/:unit:/, unitInfo.label));
        });
    };

    this.getUnitInfo = function (unit) {
        if (!_.isUndefined(_private.unitInfoCache[unit])) {
            return _private.unitInfoCache[unit];
        }
        let type;
        if (_.isUndefined(type = MathConvertUnit.unitTypeMap[unit])) {
            return false;
        }
        let unitInfo     = {
            type:     type,
            standard: MathConvertUnit.unitDefinition[type].STANDARD,
            factor:   MathConvertUnit.unitDefinition[type].UNITS[unit].factor,
            label:    MathConvertUnit.unitDefinition[type].UNITS[unit].label
        };
        unitInfo.label = EfbHelper._('unit.' + unitInfo.type + '.' + unit, unitInfo.label);
        return unitInfo;
    };

    /**
     *
     * @param {*} element
     * @param {string,"view","base"} to
     * @param {number} [value]
     * @returns {*}
     */
    this.convert = function (element, to, value) {
        element = $(element);
        let outputUnit, inputUnit, unitType;
        if (!element.length ||
            _.isUndefined(outputUnit = element.data('unitBase')) ||
            _.isUndefined(inputUnit = element.data('unitView')) ||
            _.isUndefined(unitType = element.data('unitType'))
        ) {
            throw {
                name:     "Unit convert error",
                message:  "Missing attributes to convert value for this element",
                toString: function () {
                    return this.name + ": " + this.message
                }
            }
        }
        unitType     = unitType.toUpperCase();
        let setValue = true;
        if (_.isUndefined(value)) {
            value = element.pVal();
            if (_.isNaN(value) || _.isNull(value) || typeof value !== 'number') {
                return null;
            }
            setValue = false;
        }
        if (!_.isUndefined(to) && to === 'view') {
            let outputUnitTmp = outputUnit;
            outputUnit        = inputUnit;
            inputUnit         = outputUnitTmp;
        }
        value = _private.instance.convertValue(value, inputUnit, outputUnit, unitType);
        if (setValue) {
            return $(element).pVal(value);
        }
        return value;
    };

    this.getUnitInfoFromElement = function (element) {
        element = $(element);
        return {
            type: element.data('unit-type'),
            base: element.data('unit-base'),
            view: element.data('unit-view')
        }
    };

    /**
     * Convert a unit value
     *
     * @param {number} value
     * @param {string|object} inputUnit
     * @param {string} [outputUnit]
     * @param {string} [unitType]
     * @returns {number}
     */
    this.convertValue = function (value, inputUnit, outputUnit = '', unitType = '') {
        if (typeof inputUnit === 'object') {
            outputUnit = outputUnit || inputUnit.view;
            unitType   = unitType || inputUnit.type;
            inputUnit  = inputUnit.base;
        }
        if (inputUnit === outputUnit) {
            return value;
        }
        let inputType, outputType;
        if (!_.isUndefined(unitType)) {
            unitType = unitType.toUpperCase();
        } else {
            unitType = MathConvertUnit.unitTypeMap[inputUnit];
        }
        if (_.isUndefined(inputType = MathConvertUnit.unitTypeMap[inputUnit]) ||
            _.isUndefined(outputType = MathConvertUnit.unitTypeMap[outputUnit])) {
            console.log(value, inputUnit, outputUnit, unitType);
            throw {
                name:     "Unit convert error",
                message:  "Unknown input (" + inputUnit + ") or output (" + outputUnit + ") unit ",
                toString: function () {
                    return this.name + ": " + this.message
                }
            }
        }
        if (unitType === 'FUEL') {
            let valid = false;
            // We stay in weights or volumes
            if (inputType === outputType) {
                unitType = outputType;
                valid    = true;
            }

            // We have a weight and want a volume
            if (inputType === 'WEIGHT' && outputType === 'VOLUME') {
                value     = _private.instance.convertValue(value, inputUnit, 'KILOGRAM', 'WEIGHT');
                value /= _private.instance.KEROSIN_FACTOR;
                inputUnit = 'LITER';
                unitType  = inputType = 'VOLUME';
                valid     = true;
            }

            // We have a volume and want a weight
            if (inputType === 'VOLUME' && outputType === 'WEIGHT') {
                value     = _private.instance.convertValue(value, inputUnit, 'LITER', 'VOLUME');
                value *= _private.instance.KEROSIN_FACTOR;
                inputUnit = 'KILOGRAM';
                unitType  = inputType = 'WEIGHT';
                valid     = true;
            }
            if (!valid) {
                throw {
                    name:     "Unit convert error",
                    message:  "Invalid fuel conversion",
                    toString: function () {
                        return this.name + ": " + this.message
                    }
                }
            }
        }
        if (outputType !== inputType) {
            throw {
                name:     "Unit convert error",
                message:  "InputType and OutputType does not match",
                toString: function () {
                    return this.name + ": " + this.message
                }
            }
        }
        //convert to standard
        if (_.isUndefined(outputUnit)) {
            outputUnit = MathConvertUnit.unitDefinition[unitType].STANDARD;
        }
        value = _private.con(value, MathConvertUnit.unitDefinition[unitType].UNITS[inputUnit].factor);
        value = _private.con(value, MathConvertUnit.unitDefinition[unitType].UNITS[outputUnit].factor, 'unit');
        return value;
    };

    _private.con = function (value, factor, to) {
        if (_.isUndefined(to)) {
            to = 'standard';
        }
        if (to === 'standard') {
            if (_.isObject(factor)) {
                $.each(factor, function (i, f) {
                    switch (f.op) {
                        case "/":
                            if (f.val !== 0) {
                                value /= f.val;
                            }
                            break;
                        case "+":
                            value += f.val;
                            break;
                        case "-":
                            value -= f.val;
                            break;
                        default:
                            value *= f.val;
                            break;
                    }
                });
            } else {
                value *= factor;
            }
        } else {
            if (_.isObject(factor)) {
                $.each(Object.keys(factor).reverse(), function (i, key) {
                    let f = factor[key];
                    switch (f.op) {
                        case "/":
                            if (f.val !== 0) {
                                value *= f.val;
                            }
                            break;
                        case "+":
                            value -= f.val;
                            break;
                        case "-":
                            value += f.val;
                            break;
                        default:
                            value /= f.val;
                            break;
                    }
                });
            } else {
                value /= factor;
            }
        }
        return value;
    };


    this.deg2rad = function (degree) {
        //return (degree / 180) * Math.PI;
        return degree * .017453292519943295;
    };

    this.rad2deg = function (rad) {
        // http://kevin.vanzonneveld.net
        // +   original by: Enrique Gonzalez
        // +      improved by: Brett Zamir (http://brett-zamir.me)
        // *     example 1: rad2deg(3.141592653589793);
        // *     returns 1: 180
        return rad * 57.29577951308232; // angle / Math.PI * 180
    };

    this.kmToNm = function (km) {
        return km / _private.instance.MILE_NAUTIC;
    };

    this.nmToKm = function (nm) {
        return nm * _private.instance.MILE_NAUTIC;
    };
});